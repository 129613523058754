<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId">
						<a-select placeholder="请选择影院组织" v-model:value="formState.organizationId" style="width: 190px;"
							@change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="所属影院" name="cinemaId">
						<a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" style="width: 190px;"
							@change="getCinemaId">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="放映时间">
						<a-range-picker v-model:value="time"
							:showTime="{ hideDisabledOptions: true, defaultValue:[moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }" />
					</a-form-item>
				</a-row>
				<a-row v-show="showAll">
					<a-form-item class="ui-form__item" label="订单号" name="orderNo">
						<a-input placeholder="请输入订单号" v-model:value="formState.orderNo"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="商户流水号" name="outTradeNo">
						<a-input placeholder="请输入商户流水号" v-model:value="formState.outTradeNo"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="服务费流水号" name="refundOutTradeNo">
						<a-input placeholder="请输入服务费流水号" v-model:value="formState.refundOutTradeNo"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="影片名称" name="filmName">
						<a-input placeholder="请输入影片名称" v-model:value="formState.filmName"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="用户手机" name="phone">
						<a-input placeholder="请输入用户手机" v-model:value="formState.phone"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="手机归属地" name="phoneAttribute">
						<a-input placeholder="请输入手机归属地" v-model:value="formState.phoneAttribute"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="取票号" name="ticketNum">
						<a-input placeholder="请输入取票号" v-model:value="formState.ticketNum"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="订单来源" name="source">
						<a-select v-model:value="formState.source" style="width: 190px;" placeholder="请选择订单来源">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">微信小程序</a-select-option>
							<a-select-option :value="2">抖音小程序</a-select-option>
							<a-select-option :value="3">PC购票</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="是否预警订单" name="isWarn">
						<a-select v-model:value="formState.isWarn" style="width: 190px;" placeholder="请选择是否预警订单">
							<a-select-option :value="0">否</a-select-option>
							<a-select-option :value="1">是</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="支付方式" name="payType">
						<a-select style="width: 190px;" placeholder="选择支付方式" v-model:value="formState.payType">
							<a-select-option :value="0">全部</a-select-option>
							<!-- <a-select-option :value="1">兑换券(旧)</a-select-option> -->
							<a-select-option :value="2">年卡</a-select-option>
							<a-select-option :value="3">次卡</a-select-option>
							<a-select-option :value="4">微信支付</a-select-option>
							<a-select-option :value="5">兑换券</a-select-option>
							<a-select-option :value="6">线上会员卡</a-select-option>
							<a-select-option :value="7">抖音券</a-select-option>
							<a-select-option :value="8">影城会员卡</a-select-option>
							<a-select-option :value="9">第三方线下</a-select-option>
							<a-select-option :value="10">PC现金支付</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="取票状态" name="ticketStatus">
						<a-select style="width: 190px;" placeholder="选择取票状态" v-model:value="formState.ticketStatus">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">未取票</a-select-option>
							<a-select-option :value="3">已取票</a-select-option>
							<a-select-option :value="4">已退票</a-select-option>
							<a-select-option :value="5">未退票</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="购票模式" name="buyTicketMode">
						<a-select v-model:value="formState.buyTicketMode" style="width: 190px;" placeholder="请选择购票模式">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">票务系统出票</a-select-option>
							<a-select-option :value="2">云端系统出票</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="平台发起提现" name="isSubLedger">
						<a-select style="width: 190px;" placeholder="选择取票状态" v-model:value="formState.isSubLedger">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">已发起</a-select-option>
							<a-select-option :value="0">未发起</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="售后状态" name="afterStatus">
						<a-select style="width: 190px;" placeholder="选择售后状态" v-model:value="formState.afterStatus">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">待审核</a-select-option>
							<a-select-option :value="4">已拒绝</a-select-option>
							<a-select-option :value="6">已同意</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="销售时间">
						<a-range-picker v-model:value="payTime"
							:showTime="{ hideDisabledOptions: true, defaultValue:[moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }"
							:disabledDate="disabledDate" />
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<span v-permission="['report_ticket_sale_export']">
							<ExportReport type="ticketOrderStatement" :searchData="searchData"></ExportReport>
						</span>
						<a style="margin-left: 20px;" @click="showAll = !showAll">
							{{ showAll ? '收起' :'展开' }}
							<Icon v-show="!showAll" icon="DownOutlined"></Icon>
							<Icon v-show="showAll" icon="UpOutlined"></Icon>
						</a>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list"
					:scroll="{ x: 4350 }" tableLayout="auto">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.showStartTime, 1) }}
						</template>
						<template v-if="column.key === 'day'">
							星期{{ ['日', '一', '二', '三', '四', '五', '六'][moment(record.showStartTime *1000).day()] }}
						</template>
						<template v-if="column.key === 'source'">
							<div>
								<a-tag color="green" v-if="record.source === 1">
									微信小程序
								</a-tag>
								<a-tag color="blue" v-if="record.source === 2"> 抖音小程序 </a-tag>
								<a-tag color="yellow" v-if="record.source === 3"> PC购票 </a-tag>

							</div>
						</template>
						<template v-if="column.key === 'orderInfo'">
							<p>订单号：<span style="color: #999;">{{ record.orderNo }}</span></p>
							<p>商户流水号：<span style="color: #999;">{{ record.outTradeNo || '-' }}</span></p>
						</template>
						<template v-if="column.key === 'seat'">
							<span v-for="(item, i) in record.cinemaSeatsJsonVOS" :key="i">
								<a-tag style="margin-bottom: 6px;" color="blue" v-if="item">
									{{ item.rowId }}排 {{ item.colId }}座
								</a-tag>
							</span>
						</template>
						<template v-if="column.key === 'buyTicketMode'">
							<a-tag :color=" record.buyTicketMode === 1 ? '#2db7f5' : 'cyan'">
								{{ record.buyTicketMode === 1 ? '票务系统出票' : '云端系统出票' }}
							</a-tag>
						</template>
						<template v-if="column.key === 'payType'">
							<a-tag style="font-size: 12px;"
								:color="['pink', 'orange', 'green', 'purple', 'cyan', 'blue', 'red', '#999'][record.payType - 1]">
								{{ ['兑换券(旧)', '年卡', '次卡', '微信', '兑换券', '线上会员卡', '抖音券', '影城会员卡', '第三方线下', 'PC现金'][record.payType - 1] }}支付
							</a-tag>
							<div style="margin-top: 6px;"
								v-if="record.payType === 2 || record.payType === 3 || record.payType === 6||record.payType===8">
								卡号：<span style="color: #888;">{{ record.cardNumber }}</span></div>
							<div style="margin-top: 6px;" v-if="record.payType === 1 || record.payType === 5">券号：
								<div style="color: #888;" v-for="(item, i) in record.barcodes" :key="item">
									{{ item }}
									<span v-if="i < record.barcodes.length - 1">、</span>
								</div>
							</div>
							<div v-if="record.payType === 7">
								<div v-if="record.tikTokTicketOrderInfoVOS?.length">
									<div style="border: solid 1px #eee;border-radius: 8px;padding: 6px 10px;"
										v-for="item in record.tikTokTicketOrderInfoVOS" :key="item.barcode">
										<p style="padding: 0;margin: 0;">抖音券号：{{ item.barcode || '-' }}</p>
										<p style="padding: 0;margin: 0;">抖音券名称：{{ item.title || '-' }}</p>
										<p style="padding: 0;margin: 0;">抖音商品sku：{{ item.goodsSku || '-' }}</p>
									</div>
								</div>
							</div>
						</template>
						<template v-if="column.key === 'tripartitePlatform'">
							<div v-if="record.payType == 9">抖音线下核销</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'film'">
							{{ record.filmName }}
							<!-- 场次：{{ transDateTime(record.showStartTime) }} - {{ transDateTime(record.showEndTime) }} -->
						</template>
						<template v-if="column.key === 'showTime'">
							<div>{{ transDateTime(record.showStartTime) }}</div>
							至
							<div>{{ transDateTime(record.showEndTime) }}</div>
						</template>
						<template v-if="column.key === 'userInfo'">
							<div>用户昵称：{{ record.nickname || '--' }}</div>
							<div v-if="record.phone != '88888888888'">
								<div>手机号：{{ record.phone }}</div>
								<div>手机归属地：{{ record.phoneAttribute || '-' }}</div>
							</div>
						</template>
						<template v-if="column.key === 'pcCreateUserName'">
							{{ record.pcCreateUserName || '-' }}
						</template>
						<template v-if="column.key === 'status'">
							<div v-if="record.status !== 'AFTERSALE_FINISH'">
								{{ ['未取票', '取票中', '已取票'][record.ticketStatus - 1] }}
								<div v-if="record.ticketStatus === 3">
									{{ transDateTime(record.printTime) }}
								</div>
							</div>
							<div v-else>
								已退票
							</div>
						</template>
						<template v-if="column.key === 'refundStatus'">
							<div v-if="record.status === 'AFTERSALE_FINISH'">已退票</div>
							<div v-else>未退票</div>
							<div v-if="record.status === 'AFTERSALE_FINISH'" style="font-size: 12px;color: #999;">
								<div>操作人员：{{ record.adminUser || '--' }}</div>
								<div>时间：{{ transDateTime(record.refundTime) }}</div>
								<div>已退款{{ record.refund }}元<span
										v-if="record.description">,{{ record.description }}</span></div>
								<div>售后类型：{{ ['退票退款', '仅退票', '仅退款'][record.refundType - 1] }}</div>
							</div>
						</template>
						<template v-if="column.key === 'service'">
							<div
								v-if="record.status === 'AFTERSALE_FINISH' || record.afterStatus === 1 || record.afterStatus === 6">
								<div>服务费：￥{{ record.serviceFee || 0 }}</div>
								<div>流水号：<span style="color: #888;">{{ record.refundOutTradeNo || '-' }} </span> </div>
							</div>
							<div v-else>
								--
							</div>
						</template>
						<template v-if="column.key === 'saleTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'isSubLedger'">
							<span v-if="record.source === 2">无</span>
							<span v-else>
								{{ record.isSubLedger ? '已发起' :'未发起' }}
							</span>
						</template>
						<template v-if="column.key === 'afterStatus'">
							<div v-if="record.afterStatus === 1">待审核
							</div>
							<div v-else-if="record.afterStatus === 4">已拒绝
							</div>
							<div v-else-if="record.afterStatus === 6">
								已同意
							</div>
							<div v-else>未发起售后</div>
						</template>
						<template v-if="column.key === 'isWarn'">
							<div>
								<a-tag v-if="record.isWarn" color="#2db7f5">是</a-tag>
								<a-tag v-else>否</a-tag>
							</div>
						</template>
						<template v-if="column.key === 'action'">
							<div v-if="record.afterStatus === 1">
								<a-button v-permission="['report_ticket_afterSale_apply']" size="small" type="link"
									@click="onAgree(record)">同意</a-button>
								<a-button v-permission="['report_ticket_afterSale_apply']" size="small" type="link"
									@click="onReject(record)">拒绝</a-button>
							</div>
							<div v-else>
								<a-button v-permission="['report_ticket_sale_refund']"
									v-if="record.status !== 'AFTERSALE_FINISH'" type="link"
									@click="onRefund(record)">申请退票</a-button>
								<a-button v-permission="['report_ticket_sale_print']"
									v-if="record.status !== 'AFTERSALE_FINISH'" type="link"
									@click="onPrintTap(record)">补打电影票</a-button>
							</div>
						</template>
					</template>

					<template #summary v-if="list && list.length">
						<a-table-summary-row>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell>
								合计：{{ total.seatsNum || 0 }}
							</a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell>
								合计：{{ total.oiPayment || 0 }}
							</a-table-summary-cell>
							<a-table-summary-cell>
								合计：{{ total.originalPrice || 0 }}
							</a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell>
								服务费合计：{{ total.serviceFeeTotal || 0 }}
							</a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
						</a-table-summary-row>
					</template>
				</a-table>
			</div>

			<a-modal v-model:visible="refundVisible" title="退款" @cancel="onRefundCancel" @ok="onRefundOk">
				<a-spin :spinning="refundLoading">
					<a-form ref="refundForm" name="refundForm" :model="refundData">
						<a-form-item label="退款方式" name="type">
							<a-radio-group v-model:value="refundData.type">
								<a-radio :value="1">退票退款</a-radio>
								<a-radio :value="3">仅退款</a-radio>
								<a-radio :value="2">仅退票</a-radio>
							</a-radio-group>
						</a-form-item>
						<div class="ui-warn__box" v-if="refundData.type != 1">
							<div class="ui-warn" v-if="refundData.type == 3">
								座位不释放，现金与卡券原路退回
							</div>
							<div class="ui-warn" v-if="refundData.type == 2">
								座位释放，现金与卡券不退回
							</div>
						</div>
						<a-form-item class="ui-refundForm__item" label="退款金额" name="refund"
							:rules="[{required: true, message: '请输入退款金额'}]">
							{{ detail.payment }}元
							<!--							<a-input-number v-model:value="refundData.refund" :min="0" :max="detail.payment" placeholder="请输入"></a-input-number>-->
						</a-form-item>

						<div class="ui-warn__box">
							<div class="ui-warn">
								抖音下单1个小时内，售后撤销核销抖音券；
							</div>
							<div class="ui-warn">
								抖音下单1个小时后，抖音券无法在线退款，售后只能退补差金额；
							</div>
							<div class="ui-warn">
								微信退款只能影片放映24小时内进行退款，24小时后无法退款；
							</div>
							<div class="ui-warn">
								第三方线下需在线下进行取消核销操作；
							</div>
							<div class="ui-warn">
								现金支付需要线下进行款项退还；
							</div>
						</div>

						<a-form-item label="退款备注" name="remark" :rules="[{required: true, message: '请输入退款备注'}]">
							<a-textarea v-model:value="refundData.remark" placeholder="请输入退款备注"></a-textarea>
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import {
		ticketOrderList,
		ticketAgree,
		ticketReject,
		ticketOrderRefund
	} from '@/service/modules/report.js';
	import {
		getOrganizationList,
		getCinemaList
	} from '@/service/modules/cinema.js';
	import ExportReport from '@/components/exportReport/exportReport.vue';
	import printJS from 'print-js'
	import {
		printTicket,
		collectTickets
	} from "@/service/modules/buyTickets";

	export default {
		components: {
			Icon,
			ExportReport
		},
		data() {
			return {
				loading: false,
				showAll: false,
				searchData: {},
				formState: {
					// organizationId: 0,
					cinemaId: 0,
					payType: 0,
					ticketStatus: '',
					isSubLedger: '',
					afterStatus: '',
					source: 0,
					buyTicketMode: 0
				},
				time: [],
				payTime: [],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				columns: [{
					title: '放映日期',
					key: 'createTime',
					width: 120
				}, {
					title: '星期',
					key: 'day',
					width: 100
				}, {
					title: '所属影院',
					width: 130,
					dataIndex: 'cinemaName'
				}, {
					title: '订单信息',
					key: 'orderInfo',
					width: 350
				}, {
					title: '订单来源',
					key: 'source',
					width: 120,
					align: 'center'
				}, {
					title: '影厅',
					dataIndex: 'hallName',
					width: 120
				}, {
					title: '座位',
					key: 'seat',
					width: 300
				}, {
					title: '座位数量',
					dataIndex: 'seatsNum',
					width: 120
				}, {
					title: '座位价格(元)',
					dataIndex: 'price',
					width: 120
				}, {
					title: '实付金额(元)',
					dataIndex: 'oiPayment',
					width: 120
				}, {
					title: '票房价格',
					dataIndex: 'originalPrice',
					width: 120
				}, {
					title: '支付方式',
					key: 'payType',
					width: 200
				}, {
					title: '第三方平台',
					key: 'tripartitePlatform',
					width: 200
				}, {
					title: '影片信息',
					key: 'film'
				}, {
					title: '放映时间',
					key: 'showTime',
					width: 200
				}, {
					title: '用户信息',
					key: 'userInfo',
					width: 200
				}, {
					title: '后台出票人',
					key: 'pcCreateUserName',
					width: 200
				}, {
					title: '购票模式',
					key: 'buyTicketMode',
					width: 120
				}, {
					title: '取票状态',
					key: 'status',
					width: 180
				}, {
					title: '取票号',
					dataIndex: 'ticketNum',
					width: 180
				}, {
					title: '销售时间',
					key: 'saleTime',
					width: 180
				}, {
					title: '退票信息',
					key: 'refundStatus',
					width: 200
				}, {
					title: '服务费信息',
					key: 'service',
					width: 250
				}, {
					title: '平台发起提现',
					key: 'isSubLedger',
					width: 120
				}, {
					title: '售后状态',
					key: 'afterStatus',
					width: 120
				}, {
					title: '是否预警订单',
					key: 'isWarn',
					width: 120
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 140
				}],
				organizationList: [],
				cinemaAllList: [],
				total: {},
				refundVisible: false,
				refundData: {
					type: 1,
					refund: 0
				},
				detail: {},
				refundLoading: false,
			}
		},
		created() {
			// this.onSearch();
			this.getOrganizationList();
			this.time[0] = this.moment().subtract(7, 'day').startOf('day');
			this.time[1] = this.moment().add(7, 'day').endOf('day');
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.source = this.searchData.source ? this.searchData.source : undefined;
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId :
					undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.payType = this.searchData.payType ? this.searchData.payType : undefined;
				this.searchData.isSubLedger = this.searchData.isSubLedger !== '' ? this.searchData.isSubLedger : undefined;
				this.searchData.afterStatus = this.searchData.afterStatus !== '' ? this.searchData.afterStatus : undefined;
				this.searchData.buyTicketMode = this.searchData.buyTicketMode ? this.searchData.buyTicketMode : undefined;
				if (this.time && this.time.length) {
					this.searchData.showStartTime = this.moment(this.time[0]).unix();
					this.searchData.showEndTime = this.moment(this.time[1]).unix();
				}
				if (this.payTime && this.payTime.length) {
					this.searchData.startTime = this.moment(this.payTime[0]).unix();
					this.searchData.endTime = this.moment(this.payTime[1]).unix();
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.cinemaAllList = [];
				this.time = [];
				this.payTime = [];
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				const postData = {
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData,
				}
				this.loading = true;
				try {
					let {
						data
					} = await ticketOrderList(postData);
					setTimeout(() => {
						this.total = data.list[0];
						this.list = data.list.splice(1);
						this.pagination.total = data.totalCount;
					}, 200);
					this.loading = false;
				} catch (error) {
					this.loading = false;
					// 	console.log('getData:error==>', error);
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				// this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onAgree(item) {
				this.$confirm({
					title: '提示',
					content: '确定同意用户退款申请吗？',
					onOk: async () => {
						try {
							this.loading = true;
							let ret = await ticketAgree({
								id: item.orderAfterSaleId
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('审核成功!');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
			onReject(item) {
				this.$confirm({
					title: '提示',
					content: '确定拒绝用户退款申请吗？',
					onOk: async () => {
						try {
							this.loading = true;
							let ret = await ticketReject({
								id: item.orderAfterSaleId
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('审核成功!');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
			onRefund(item) {
				this.detail = item;
				this.refundData = {
					type: 1,
					refund: 0
				};
				this.refundVisible = true;
			},
			onRefundCancel() {
				this.$refs.refundForm.resetFields();
				this.refundVisible = false;
				this.detail = {};
				this.refundData = {
					type: 1,
					refund: 0
				};
			},
			onRefundOk() {
				const zero = 0;
				this.$refs.refundForm.validateFields().then(() => {
					this.$confirm({
						title: '提示',
						content: '确定需要退票吗？',
						onOk: async () => {
							this.refundLoading = true;
							try {
								let ret = await ticketOrderRefund({
									orderId: this.detail.id,
									refund: this.detail.payType === 6 || this.detail
										.payType === 8 ? zero.toFixed(2) : this.detail.payment,
									description: this.refundData.remark,
									refundType: this.refundData.type
								});
								this.refundLoading = false;
								if (ret.code === 200) {
									this.$message.success('操作成功');
									this.refundVisible = false;
									this.getData();
								}
							} catch (e) {
								this.refundLoading = false;
								console.log(e)
							}
						}
					})
				});
			},
			async onPrintTap(record) {
				const printRet = await printTicket({
					orderId: record.id
				});
				if (printRet.code == 200) {
					await collectTickets({
						orderId: record.id
					});
					printJS({
						type: 'pdf',
						printable: printRet.data,
						onPrintDialogClose: () => {
							// 关闭打印弹窗回调
						},
						onLoadingStart: () => {
							// 开始加载pdf完成回调
						},
						onLoadingEnd: () => {
							// 加载pdf完成回调
						},
						onError: (err) => {
							// TODO 打印异常处理
							console.log(err)
						}
					})
				}
			},
			getCinemaId(value) {
				this.cinemaIds = value;
			},
			disabledDate(current) {
				return current && current >= this.moment().endOf('day');
			}

		}
	}
</script>

<style>
	.ui-refundForm__item {
		margin-bottom: 0px;
	}
</style>
<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}

	.ui-warn__box {
		margin: 10px 0 20px 0;
		padding: 10px 20px;
		background-color: antiquewhite;
		border-radius: 10px;
	}

	.ui-warn {
		color: #737272;
	}
</style>